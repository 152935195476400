import { inject, bindable } from 'aurelia-framework';
import { DataGridCustomAttribute } from './data-grid';

@inject(DataGridCustomAttribute, Element)
export class DataGridSortCustomAttribute {
    @bindable key;
    @bindable custom;
    @bindable default;
    @bindable reverse = false;

    order = 0;
    orderClasses = ['dg-desc', 'dg-sortable', 'dg-asc'];

    ignoreEvent = false;

    constructor(dataGrid, element) {
        this.dataGrid = dataGrid;
        this.element = element;

        this.rowSelectedListener = () => {
            this.handleHeaderClicked();
        };

        this.sortChangedListener = () => {
            this.handleSortChanged();
        };
    }

    handleSortChanged() {
        if (!this.ignoreEvent) {
            this.order = 0;
            this.setClass();
        } else {
            this.ignoreEvent = false;
        }
    }

    attached() {
        if (this.key === null && this.custom === null) {
            throw new Error('Must provide a key or a custom sort function.');
        }

        this.element.style.cursor = 'pointer';
        this.element.classList.add('dg-sort');
        this.element.classList.add('sortable');

        this.element.addEventListener('click', this.rowSelectedListener);
        this.dataGrid.addSortChangedListener(this.sortChangedListener);

        this.handleDefaultSort();
        this.setClass();
    }

    detached() {
        this.element.removeEventListener('click', this.rowSelectedListener);
        this.dataGrid.removeSortChangedListener(this.sortChangedListener);
    }

    handleDefaultSort() {
        if (this.dataGrid.sortKey && this.dataGrid.sortKey === this.key) {
            // data grid sort overrides the default
            this.order = this.dataGrid.sortOrder;
            this.doSort();
            return;
        }

        if (this.default) {
            this.order = this.default === 'desc' ? -1 : 1;
            this.doSort();
        }
    }

    doSort() {
        this.ignoreEvent = true;
        const sortOrder = this.reverse ? this.order * -1 : this.order
        this.dataGrid.sortChanged(this.key, this.custom, sortOrder);
    }

    setClass() {
        this.orderClasses.forEach(next => this.element.classList.remove(next));
        this.element.classList.add(this.orderClasses[this.order + 1]);
    }

    handleHeaderClicked() {
        this.order = this.order === 0 || this.order === -1 ? this.order + 1 : -1;
        this.setClass();
        this.doSort();
    }
}
