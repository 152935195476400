import { c } from 'common/common';
import { ROLE } from 'common/constants';

export class RouterConfigBase {
    _ea;
    _security;
    _menus;
    _menuArea;
    _hasPageTitleProperties;
    _baseHandlers = [];

    constructor(ea, security, menus, menuArea, hasPageTitleProperties = true) {
        this._ea = ea;
        this._security = security;
        this._menus = menus;
        this._menuArea = menuArea;
        this._hasPageTitleProperties = hasPageTitleProperties;
    }

    _attached() {
        this._setPageTitle();
        this._baseHandlers.push(this._ea.subscribe(c.EventKeys.navigation.changed, () => this._setPageTitle()));
        if (this._hasPageTitleProperties) {
            this._baseHandlers.push(this._ea.subscribe(c.EventKeys.site.setPageTitle, (data) => this._setPageTitle(data)));
        }
    }

    _detached() {
        this._baseHandlers.forEach(h => h.dispose());
        this._baseHandlers = [];
    }

    setMenu() {
        if (!this._menuArea) return;
        this._ea.publish(c.EventKeys.menu.changed, { area: this._menuArea });
    }

    _setPageTitle(data) {
        try {
            const pageCode = this._mobileMenuCode();
            const pageSettings = this.router.currentInstruction.config.settings ?? {};
            this.pageTitle = data && data.title ? data.title : pageSettings.title;
            this.pageDescription = data && data.description ? data.description : pageSettings.description;
            this.pageIcon = data && data.icon ? data.icon : pageSettings.icon ?? 'fa-duotone fa-users';
            this.pageInMobileMenu = this._menus.isOnMobileMenu('Page', pageCode);
            this.canAddToMobileMenu = this._security.isInRole([ROLE.Admin, ROLE.DashboardAdmin]) && pageSettings.canAddToMobileMenu;
            this.favoriteType = pageSettings.favoriteType;
            if (this.router.currentInstruction.params) {
                this.favoriteId = this.router.currentInstruction.params.id ?? this.router.currentInstruction.params.code ?? this.router.currentInstruction.params.slug;
            }
            if (!this._hasPageTitleProperties) {
                window.setTimeout(() => this._ea.publish(c.EventKeys.site.setPageTitle, { title: this.pageTitle, description: this.pageDescription }), 0);
            }
        } catch (err) {
            console.log(err);
        }
    }

    _mobileMenuCode() {
        return `#${this.router.baseUrl}${this.router.currentInstruction.fragment}`;
    }
}