import { computedFrom, bindable, bindingMode } from 'aurelia-framework';
import { DOM } from 'aurelia-pal';

export class AutocompleteBase {
    element;
    _api;
    _autocompleteEndpoint;
    _endpointPayloadFormatter;

    lastFindPromise;
    justSelected = false;
    previousValue = null;
    initial = true;

    dropdownToggle;
    dropdownMenu;

    showResults = false;

    @bindable name = '';
    @bindable minInput = 3;
    @bindable debounce = 500;
    @bindable onlyAgencies = null;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value = '';
    @bindable selected;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) result = null;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) results = [];
    @bindable size = '';
    @bindable focusInput = false;
    @bindable includeDirectUpline = false;
    @bindable roleId;
    @bindable includeMentees = false
    @bindable placeholder = '';

    constructor(element, api, endpoint, endpointPayloadFormatter) {
        this.element = element;
        this._api = api;
        this._autocompleteEndpoint = endpoint;
        this._endpointPayloadFormatter = endpointPayloadFormatter;
    }

    _bind() {
        this.value = this.label(this.result);
        this.dropdownToggle = $(this.dropdownToggle);
        this.dropdownMenu = $(this.dropdownToggle.parent().find('.dropdown-menu')[0]);
    }

    showDropdown() {
        if (!this.dropdownMenu.hasClass('show') && this.dropdownToggle && this.dropdownToggle.dropdown) {
            this.dropdownToggle.dropdown('toggle');
        }
    }

    setFocus(value, event) {
        function isDescendant(parent, child) {
            let node = child.parentNode;

            while (node !== null) {
                if (node === parent) return true;
                node = node.parentNode;
            }
            return false;
        }

        // If descendant, don't toggle dropdown so that other listeners will be called.
        if (event && event.relatedTarget && isDescendant(this.element, event.relatedTarget)) return true;

        if (value) return this.valueChanged();
        this.showResults = value;
    }

    labelWithMatches(result) {
        let label = this.label(result, true);
        if (typeof label !== 'string') {
            return '';
        }

        return label.replace(this.regex, match => {
            return `<strong>${match}</strong>`;
        });
    }

    handleKeyUp(event) {
        if (event.keyCode !== 27) return;
        if (this.showResults) event.stopPropagation();
        this.setFocus(false);
        return true;
    }

    handleKeyDown(event) {
        if (event.keyCode === 27) return;

        if (event.keyCode === 40 || event.keyCode === 38) {
            this.selected = this.nextFoundResult(this.selected, event.keyCode === 38);
            return event.preventDefault();
        }

        if (event.keyCode === 9 || event.keyCode === 13) {
            if (this.showResults) {
                event.stopPropagation();
                event.preventDefault();
            }

            if (this.results.length !== 0 && this.showResults) {
                this.onSelect();
            }
        } else if (event.keyCode !== 37 && event.keyCode !== 39) {
            // ensure dropdown is uncollapsed when there are results while user types
            if (this.results.length > 0) {
                this.showDropdown();
            }
            this.setFocus(true);
        }

        return true;
    }

    nextFoundResult(current, reversed) {
        let index = (this.results.indexOf(current) + (reversed ? -1 : 1)) % (this.results.length);

        if (index < 0) index = this.results.length - 1;

        return this.results[index];
    }

    /**
     * Set the text in the input to that of the selected item and set the
     * selected item as the value. Then hide the results(dropdown)
     *
     * @param {Object} [result] when defined uses the result instead of the this.selected value
     *
     * @returns {boolean}
     */
    onSelect(result) {
        result = (arguments.length === 0) ? this.selected : result;
        this.justSelected = true;
        this.value = this.label(result);
        this.previousValue = this.value;
        this.selected = result;
        this.result = result;

        this.element.dispatchEvent(new CustomEvent('selected', { bubbles: true, detail: this.result }));

        this.setFocus(false);

        this.fireBlur();
        return true;
    }

    resultChanged() {
        if (this.selected !== this.result) {
            this.onSelect(this.result);
        }
    }

    sort(items) {
        return items;
    }

    ctr = 0;
    /**
     * when search string changes perform a request, assign it to results
     * and select the first result by default.
     *
     * @returns {Promise}
     */
    valueChanged() {
        this.ctr++;
        let initial = this.initial;
        let justSelected = this.justSelected;

        if (!this.shouldPerformRequest()) {
            this.previousValue = this.value;
            if (justSelected) {
                this.showResults = false;
            } else {
                this.showResults = !initial && !(this.results.length === 0);
            }

            return Promise.resolve();
        }

        if (!this.hasEnoughCharacters()) {
            this.results = [];
            this.previousValue = this.value;
            this.showResults = false;

            return Promise.resolve();
        }

        // this.result = null;
        this.showResults = true;

        // when resource is not defined it will not perform a request. Instead it
        // will search for the first items that pass the predicate
        if (this.items) {
            this.results = this.sort(this.items);

            return Promise.resolve();
        }

        let lastFindPromise = this.findResults(this._endpointPayloadFormatter(this.value))
            .then(results => {
                if (this.lastFindPromise !== lastFindPromise) {
                    return;
                }

                this.previousValue = this.value;
                this.lastFindPromise = false;
                this.results = this.sort(results || []);

                if (this.results.length !== 0) {
                    this.selected = this.results[0];
                }
            });

        this.lastFindPromise = lastFindPromise;
    }

    @computedFrom('value')
    get regex() {
        return new RegExp(this.value, 'gi');
    }

    /**
     * returns true when a request will be performed on a search change
     *
     * @returns {Boolean}
     */
    shouldPerformRequest() {
        if (this.justSelected === true) {
            this.justSelected = false;
            return false;
        }

        if (this.initial) {
            this.initial = false;
            return false;
        }

        return this.value !== this.previousValue;
    }

    /**
     * Returns whether or not value has enough characters (meets minInput).
     *
     * @returns {boolean}
     */
    hasEnoughCharacters() {
        return ((this.value && this.value.length) || 0) >= this.minInput;
    }

    async findResults(query) {
        try {
            return this._api.post(this._autocompleteEndpoint, query);
        } catch (err) {
            console.log('ERROR', err);
        }
    }

    clear() {
        this.value = '';
        this.results = [];
        this.onSelect(null);
    }

    fireBlur() {
        this.element.dispatchEvent(DOM.createCustomEvent('blur'));
    }
}
