import { c } from 'common/common';

export class MenuController {
    _ea;
    _menuArea;
    _baseHandlers = [];

    constructor(ea, menuArea) {
        this._ea = ea;
        this._menuArea = menuArea;
    }

    setMenu() {
        this._ea.publish(c.EventKeys.menu.changed, { area: this._menuArea });
    }

    _attached() {
        this._baseHandlers.push(this._ea.subscribe(c.EventKeys.site.setPageTitle, (data) => this._setPageTitle(data)));
    }

    _detached() {
        this._baseHandlers.forEach(h => h.dispose());
        this._baseHandlers = [];
    }

    _setPageTitle(data) {
        try {
            // const pageCode = this._mobileMenuCode();
            const pageSettings = this.router.currentInstruction.config.settings ?? {};
            this.pageTitle = data && data.title ? data.title : pageSettings.title;
            this.pageDescription = data && data.description ? data.description : pageSettings.description;
            this.pageIcon = data && data.icon ? data.icon : pageSettings.icon ?? 'fa-duotone fa-users';
            // this.pageInMobileMenu = this._menus.isOnMobileMenu('Page', pageCode);
            // this.canAddToMobileMenu = this.security.isInRole([ROLE.Admin, ROLE.DashboardAdmin]) && pageSettings.canAddToMobileMenu;
            // this.favoriteType = pageSettings.favoriteType;
            // if (this.router.currentInstruction.params) {
            //     this.favoriteId = this.router.currentInstruction.params.id ?? this.router.currentInstruction.params.code ?? this.router.currentInstruction.params.slug;
            // }
        } catch (err) {
            console.log(err);
        }
    }
}
