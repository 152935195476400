import { PLATFORM } from 'aurelia-pal';
import { DataGridCustomAttribute } from './data-grid';
import { DataGridPaginationCustomElement } from './data-grid-pagination';
import { DataGridSelectCustomAttribute } from './data-grid-select';
import { DataGridSortCustomAttribute } from './data-grid-sort';

/*
https://tochoromero.github.io/aurelia-table/
*/

export function configure(config) {
    config.globalResources(PLATFORM.moduleName('./data-grid'));
    config.globalResources(PLATFORM.moduleName('./data-grid-pagination'));
    config.globalResources(PLATFORM.moduleName('./data-grid-select'));
    config.globalResources(PLATFORM.moduleName('./data-grid-sort'));
}

export {
    DataGridCustomAttribute,
    DataGridPaginationCustomElement,
    DataGridSelectCustomAttribute,
    DataGridSortCustomAttribute,
};
