export class ServiceWithListNav {
    _listNavKey;

    constructor(key) {
        this._listNavKey = key;
    }

    clearNav() {
        localStorage.removeItem(this._listNavKey);
    }

    setNavListIds(ids) {
        try {
            localStorage.setItem(this._listNavKey, JSON.stringify(ids));
        } catch (err) {
            console.log(err);
        }
    }

    setNavList(items) {
        try {
            const ids = items.map(x => x.id);
            localStorage.setItem(this._listNavKey, JSON.stringify(ids));
        } catch (err) {
            console.log(err);
        }
    }

    getNav(currentId) {
        try {
            const ids = JSON.parse(localStorage.getItem(this._listNavKey) || '[]');
            const currentIndex = ids.indexOf(currentId);
            if (currentIndex < 0) {
                this.clearNav();
                return { hasNav: false };
            }
            const previousId = currentIndex > 0 ? ids[currentIndex - 1] : undefined;
            const nextId = currentIndex >= 0 && currentIndex < ids.length ? ids[currentIndex + 1] : undefined;
            return {
                hasNav: previousId || nextId ? true : false,
                previousId,
                nextId
            };
        } catch (err) {
            console.log(err);
            return { hasNav: false };
        }
    }

    setNavListWithNames(items) {
        try {
            const ids = [];
            items.forEach(x => ids.push({ id: x.id, name: x.name }));
            localStorage.setItem(this._listNavKey, JSON.stringify(ids));
        } catch (err) {
            console.log(err);
        }
    }

    getNavWithNames(currentId) {
        try {
            const ids = JSON.parse(localStorage.getItem(this._listNavKey) || '[]');
            const currentIndex = ids.findIndex(x => x.id === currentId);
            if (currentIndex < 0) {
                this.clearNav();
                return { hasNav: false };
            }
            const previous = currentIndex > 0 ? ids[currentIndex - 1] : undefined;
            const next = currentIndex >= 0 && currentIndex < ids.length ? ids[currentIndex + 1] : undefined;
            return {
                hasNav: previous || next ? true : false,
                previous: previous,
                next: next,
            };
        } catch (err) {
            console.log(err);
            return { hasNav: false };
        }
    }
}