import { REPORT_TIME_PERIOD } from 'common/constants';
import { c } from 'common/common';
import moment from 'moment';

export class TimePeriodNavigatorBase {
    _id;
    _key;
    _ea;
    asOfDate;

    _handlers = [];

    constructor(key, ea) {
        this._key = key;
        this._ea = ea;
        this._id = c.Helpers.uniqueId();
    }

    _attached() {
        this._handlers.push(this._ea.subscribe(c.EventKeys.dashboard.syncTimePeriod, (data) => {
            this._syncMe(data.id, data.asOfDate, data.timePeriod);
        }));
        this._handlers.push(this._ea.subscribe(c.EventKeys.dashboard.builderDetached, () => {
            this._detached();
        }));
    }

    _detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    previous() {
        if (!this.canGoPrevious) return;
        this.asOfDate = moment(this.currentPeriod.start).add(-1, 'days');
        this._loadData();
    }

    next() {
        if (!this.canGoNext) return;
        this.asOfDate = moment(this.currentPeriod.end).add(1, 'day');
        this._loadData();
    }

    current() {
        this.asOfDate = undefined;
        this._loadData();
    }

    initializeTimePeriodNavigation(fromSync = false) {
        this.initialLoad = false;
        if (!this.asOfDate) {
            this.initialLoad = true;
            this.asOfDate = moment().startOf('day');
        }
        if (!fromSync && this._asOfDateInitialized) this._handleSync();
        if (!this._asOfDateInitialized) this._asOfDateInitialized = true;
    }

    _handleSync() {
        if (!this.sync || !this._ea) return;
        this._ea.publish(c.EventKeys.dashboard.syncTimePeriod, { id: this._id, asOfDate: this.asOfDate.toISOString(), timePeriod: this.timePeriod });
    }

    _syncMe(id, asOfDate, timePeriod) {
        if (id === this._id) return;
        if (!this.sync) return;
        if (timePeriod !== this.timePeriod) return;
        this.asOfDate = moment(asOfDate);
        this._loadData(true);
    }

    setTimePeriodNavigationDisplays() {
        if (!this.currentPeriod) return;
        this.timePeriodDisplay = this.getTimePeriodDisplay(this.currentPeriod);
        this.canGoNext = !moment(this.currentPeriod.end).isAfter(moment().endOf('day'));
        const start = moment(this.currentPeriod.start);
        let priorPeriodsAllowed = undefined;
        let momentTimePeriod = undefined;
        if (this.priorMonthsAllowed !== undefined) {
            priorPeriodsAllowed = this.priorMonthsAllowed;
            momentTimePeriod = 'month';
        }

        if (this.initialLoad && priorPeriodsAllowed !== undefined) {
            this.initialLoad = false;
            const goBackPeriods = -1 * priorPeriodsAllowed;
            this.earliestDate = start.clone().add(goBackPeriods, momentTimePeriod);
        }
        if (!this.earliestDate) this.canGoPrevious = true;
        else {
            const previousPeriodStartDate = start.clone().add(-1, momentTimePeriod);
            this.canGoPrevious = previousPeriodStartDate.isSameOrAfter(this.earliestDate);
        }
    }

    getTimePeriodDisplay(period) {
        if (!this.timePeriod) return '';
        switch (this.timePeriod) {
            case REPORT_TIME_PERIOD.DTD:
                return moment.utc(period.start).format('MMM D');
            case REPORT_TIME_PERIOD.MTD:
                const periodEnd = moment(period.end);
                const mtdFormat = periodEnd.year() !== moment().year() ? 'MMM YYYY' : 'MMMM';
                return moment(period.end).format(mtdFormat);
            case REPORT_TIME_PERIOD.YTD:
                return moment(period.end).format('YYYY');
            default:
                return `${moment(period.start).format('l')} - ${moment(period.end).format('l')}`;
        }
    }

    getTimePeriodProgress() {
        if (!this.timePeriod || !this.currentPeriod) return;
        try {
            const start = moment(this.currentPeriod.start);
            const end = moment(this.currentPeriod.end);
            const today = moment();
            const totalHours = end.diff(start, 'hours');
            const hoursFromStart = today.diff(start, 'hours');
            const remainingHours = totalHours - hoursFromStart;
            let percentComplete = Math.round((hoursFromStart / totalHours) * 100 * 10 / 10);
            if (percentComplete > 100) percentComplete = 100;
            const percentRemaining = 100 - percentComplete;
            return {
                totalHours,
                hoursFromStart,
                remainingHours,
                percentComplete,
                percentRemaining, 
            };
        } catch (err) {
            console.log(err);
        }
    }
}
