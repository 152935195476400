import { inject, bindable, bindingMode, BindingEngine } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import moment from 'moment';

@inject(BindingEngine, Element, EventAggregator)
export class DataGridCustomAttribute {
    element;
    _ea;

    @bindable id;
    @bindable data;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) displayData;

    @bindable filters;

    @bindable({ defaultBindingMode: bindingMode.twoWay }) currentPage;
    @bindable pageSize;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) totalItems;

    @bindable({ defaultBindingMode: bindingMode.twoWay }) api;

    @bindable filterEventKey;
    @bindable orderedIdsEventKey;

    @bindable reapply;

    isAttached = false;

    _optionsStorageKey;
    sortKey;
    sortOrder;
    sortChangedListeners = [];
    beforePagination = [];

    dataObserver;
    filterObservers = [];

    _localStorage;

    constructor(bindingEngine, element, ea) {
        this.element = element;
        this.bindingEngine = bindingEngine;
        this._localStorage = window.localStorage;
        this._ea = ea;
    }

    bind() {
        if (Array.isArray(this.data)) {
            this.dataObserver = this.bindingEngine.collectionObserver(this.data).subscribe(() => this.applyPlugins());
        }
        this._setFilterObservers();

        this.api = {
            revealItem: (item) => this.revealItem(item),
        };

        this._loadOptions();
    }

    _setFilterObservers() {
        if (Array.isArray(this.filters)) {
            for (let filter of this.filters) {
                let observer = this.bindingEngine.propertyObserver(filter, 'value').subscribe(() => this.filterChanged());
                this.filterObservers.push(observer);
            }
        }
    }

    filtersChanged() {
        if (!this.filters) return;
        this._clearFilterBindings();
        this._setFilterObservers();
    }

    attached() {
        this.isAttached = true;
        this.applyPlugins();
    }

    detached() {
        if (this.dataObserver) {
            this.dataObserver.dispose();
        }
        this._clearFilterBindings();
    }

    _clearFilterBindings() {
        for (let observer of this.filterObservers) {
            observer.dispose();
        }
    }

    filterChanged() {
        if (this.hasPagination()) {
            this.currentPage = 1;
        }
        this.applyPlugins();
    }

    currentPageChanged() {
        this.applyPlugins();
    }

    pageSizeChanged() {
        this.applyPlugins();
    }

    reapplyChanged() {
        if (!this.reapply) return;
        this.applyPlugins();
    }

    /**
     * Copies the data into the display data
     */
    getDataCopy() {
        return [].concat(this.data);
    }

    /**
     * Applies all the plugins to the display data
     */
    applyPlugins() {
        if (!this.isAttached || !this.data) {
            return;
        }

        let localData = this.getDataCopy();

        let filtered = false;
        if (this.hasFilter()) {
            localData = this.doFilter(localData);
            filtered = true;
        }

        if ((this.sortKey || this.customSort) && this.sortOrder !== 0) {
            this.doSort(localData);
            this.element.dispatchEvent(new CustomEvent('sorted', { bubbles: true, detail: { sortKey: this.sortKey, sortOrder: this.sortOrder } }));
        } else {
            this._saveOptions();
        }

        this.totalItems = localData.length;

        this.element.dispatchEvent(new CustomEvent('changed', { bubbles: true, detail: { filteredData: localData } }));

        // Fire the event before setting pagination, copy the ids
        if (this.orderedIdsEventKey && localData) {
            this._ea.publish(this.orderedIdsEventKey, localData.map(x => x.id));
        }

        if (this.hasPagination()) {
            this.beforePagination = [].concat(localData);
            localData = this.doPaginate(localData);
        }

        this.displayData = localData;
        if (filtered && this.filterEventKey) this._ea.publish(this.filterEventKey);
    }

    doFilter(toFilter) {
        let filteredData = [];

        for (let item of toFilter) {
            let passed = true;

            for (let filter of this.filters) {
                if (!this.passFilter(item, filter)) {
                    passed = false;
                    break;
                }
            }

            if (passed) {
                filteredData.push(item);
            }
        }

        return filteredData;
    }

    passFilter(item, filter) {
        if (typeof filter.custom === 'function' && !filter.custom(filter.value, item)) {
            return false;
        }

        if (filter.value === null || filter.value === undefined || !Array.isArray(filter.keys)) {
            return true;
        }

        for (let key of filter.keys) {
            let value = this.getPropertyValue(item, key);
            if (value !== null && value !== undefined) {
                value = value.toString().toLowerCase();

                if (this.matchesFilterValue(key, value, filter.value)) {
                    return true;
                }
            }
        }
        return false;
    }

    matchesFilterValue(key, value, filterValue) {
        // If the filter value is an array, the values are or'ed. There's a match if any of the filterValues are found in the value
        let convertedValue = value;
        let filterValues = [];
        if (Array.isArray(filterValue)) {
            for (let filter of filterValue) {
                filterValues.push(filter);
            }
        } else if (key.indexOf('Date') >= 0) {
            filterValues.push(filterValue);
            convertedValue = moment(value.toUpperCase(), 'YYYY-MM-DDThh:mm:ss').format('l');
        } else {
            filterValues.push(filterValue);
        }

        for (let filter of filterValues) {
            if (convertedValue.indexOf(filter.toString().toLowerCase()) > -1) {
                return true;
            }
        }
        return false;
    }

    doSort(toSort) {
        this._saveOptions();

        toSort.sort((a, b) => {
            if (typeof this.customSort === 'function') {
                return this.customSort(a, b, this.sortOrder);
            }

            let val1;
            let val2;

            if (typeof this.sortKey === 'function') {
                val1 = this.sortKey(a, this.sortOrder);
                val2 = this.sortKey(b, this.sortOrder);
            } else if (this.sortKey === 'fl-name') {
                val1 = `${this.getPropertyValue(a, 'firstName')} ${this.getPropertyValue(a, 'lastName')}`;
                val2 = `${this.getPropertyValue(b, 'lastName')} ${this.getPropertyValue(b, 'lastName')}`;
            } else {
                val1 = this.getPropertyValue(a, this.sortKey);
                val2 = this.getPropertyValue(b, this.sortKey);
            }

            if (val1 === null) val1 = '';
            if (val2 === null) val2 = '';

            if (this.isNumeric(val1) && this.isNumeric(val2)) {
                return (val1 - val2) * this.sortOrder;
            }

            if ((moment(val1).isValid() && moment(val2).isValid())
                || (!val1 && moment(val2).isValid())
                || (!val2 && moment(val1).isValid())) {
                if (this.sortOrder > 0) {
                    if (!val1) return -1;
                    if (!val2) return 1;
                    return moment(val1).isBefore(moment(val2)) ? -1 : 1;
                }
                if (!val1) return 1;
                if (!val2) return -1;
                return moment(val2).isBefore(moment(val1)) ? -1 : 1;
            }

            let str1 = val1 ? val1.toString() : '';
            let str2 = val2 ? val2.toString() : '';

            return str1.localeCompare(str2) * this.sortOrder;
        });
    }

    /**
     * Retrieves the value in the object specified by the key path
     * @param object the object
     * @param keyPath the path
     * @returns {*} the value
     */
    getPropertyValue(object, keyPath) {
        keyPath = keyPath.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        keyPath = keyPath.replace(/^\./, '');           // strip a leading dot
        let a = keyPath.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            let k = a[i];
            if (object && k in object) {
                object = object[k];
            } else {
                return;
            }
        }
        return object;
    }

    isNumeric(toCheck) {
        return !isNaN(parseFloat(toCheck)) && isFinite(toCheck);
    }

    doPaginate(toPaginate) {
        if (toPaginate.length <= this.pageSize) {
            return toPaginate;
        }

        let start = (this.currentPage - 1) * this.pageSize;

        let end = start + this.pageSize;

        return toPaginate.slice(start, end);
    }

    hasFilter() {
        return Array.isArray(this.filters) && this.filters.length > 0;
    }

    hasPagination() {
        return this.currentPage > 0 && this.pageSize > 0;
    }

    dataChanged() {
        if (this.dataObserver) {
            this.dataObserver.dispose();
        }
        if (!this.data) return;

        this.dataObserver = this.bindingEngine.collectionObserver(this.data)
            .subscribe(() => this.applyPlugins());

        this.applyPlugins();
    }

    sortChanged(key, custom, order) {
        this.sortKey = key;
        this.customSort = custom;
        this.sortOrder = order;
        this.applyPlugins();
        this.emitSortChanged();
    }

    addSortChangedListener(callback) {
        this.sortChangedListeners.push(callback);
    }

    removeSortChangedListener(callback) {
        this.removeListener(callback, this.sortChangedListeners);
    }

    emitSortChanged() {
        for (let listener of this.sortChangedListeners) {
            listener();
        }
    }

    removeListener(callback, listeners) {
        let index = listeners.indexOf(callback);

        if (index > -1) {
            listeners.splice(index, 1);
        }
    }

    revealItem(item) {
        if (!this.hasPagination()) {
            return true;
        }

        let index = this.beforePagination.indexOf(item);

        if (index === -1) {
            return false;
        }

        this.currentPage = Math.ceil((index + 1) / this.pageSize);

        return true;
    }

    _loadOptions() {
        try {
            if (!this.id) return;
            this._optionsStorageKey = `fqa-data-grid-${this.id}`;
            const options = JSON.parse(this._localStorage.getItem(this._optionsStorageKey) || '{}');
            if (!this.sortKey) this.sortKey = options.sortKey || undefined;
            if (!this.sortOrder) this.sortOrder = options.sortOrder || undefined;
        } catch (err) {
            // do no harm, just won't load
        }
    }

    _saveOptions() {
        try {
            if (!this._optionsStorageKey) return;
            // Do not save if sort order is 0 (falsey) - means no sort. Only a sort if 1 or -1
            const options = {
                sortKey: this.sortOrder ? this.sortKey || '' : '',
                sortOrder: this.sortOrder ? this.sortOrder || '' : '',
            };
            this._localStorage.setItem(this._optionsStorageKey, JSON.stringify(options));
        } catch (err) {
            // do no harm, just won't save
        }
    }
}
